import React from 'react'
import { Link } from 'gatsby'

import '../styles/styles.scss'

import LOGO from '../img/EQUE_Logo.svg'
import LOGO_WHITE from '../img/EQUE_Logo_white.svg'
import { FaApple, FaSpotify, FaTwitter } from 'react-icons/fa'
import { FiChevronRight, FiInstagram } from 'react-icons/fi'
import { IoMdClose } from 'react-icons/io'
import { AiFillFacebook } from 'react-icons/ai'

import ReactTooltip from 'react-tooltip'
import { StickyContainer, Sticky } from 'react-sticky'

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class Header extends React.Component {
  state = {
    scrollDir: "up"
  };

  componentDidMount() {
    this.lastScrollTop = null;
    document.addEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const { scrollDir } = this.state;
    if (typeof window !== 'undefined') {
      var st = window.pageYOffset || document.documentElement.scrollTop
      if (st > this.lastScrollTop) {
        // console.log("down")

        if (scrollDir === "up") {
          this.setState({
            ...this.state,
            scrollDir: "down"
          });
        }
      } else {
        // console.log("up")

        if (scrollDir === "down") {
          this.setState({
            ...this.state,
            scrollDir: "up"
          });
        }
      }

      this.lastScrollTop = st <= 0 ? 0 : st;
    }
  }

  render() {
    const { props } = this;
    const { scrollDir } = this.state;

    return (
      <>
        <div className={"site-header" + (scrollDir === "down" ? " hidden" : "")}>
          <div className="container">
            <nav
              className="navbar"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="columns is-mobile">
                <div className="column is-narrow logo">
                  <Link to="/">
                    <img src={LOGO} className="logo" />
                  </Link>
                </div>

                <div className="column hamburger">
                  <div className="hbwrapper">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault()

                        props.handleSideNavToggle()
                      }}
                    ></a>
                    <span className="top"></span>
                    <span className="bottom"></span>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div style={{ paddingBottom: '4em' }}></div>
      </>
    )
  }
}

export const Footer = () => (
  <footer>
    <div className="container">
      <div className="columns ui-grid">
        <div className="column is-10-tablet is-12-mobile is-6-desktop is-6-widescreen is-6-fullhd">
          <p className="description">
            Get new music delivered straight to your inbox.
          </p>
        </div>
      </div>

      <div className="columns ui-grid">
        <div className="column is-8-tablet is-10-mobile is-4-desktop is-4-widescreen is-4-fullhd">
          <p className="footer-message">
            Join the community and be the first to know about new interviews and
            playlists.
          </p>
        </div>
      </div>

      <div className="columns ui-grid">
        <div className="column is-8-tablet is-10-mobile is-4-desktop is-4-widescreen is-4-fullhd">
          <form
            action="https://eque.us16.list-manage.com/subscribe/post?u=23464b6eb766b65d87cde894f&amp;id=9235cd5921"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
          >
            <label className="input" for="footer_subscribe">
              <input
                name="EMAIL"
                type="email"
                placeholder="Your email"
                id="footer_subscribe"
                required="true"
              />

              <a href="#" type="submit" onClick={e => {
                e.preventDefault();
                // e.stopPropogation();
                document.forms['mc-embedded-subscribe-form'].submit();
                return false;
              }}>
                <FiChevronRight />
              </a>
            </label>
          </form>
        </div>
      </div>

      <div className="links">
        <a target="_blank" href="http://instagram.com/eque_mag">
          <FiInstagram />
        </a>
        <a target="_blank" href="https://twitter.com/eque_mag/">
          <FaTwitter />
        </a>
        <a target="_blank" href="https://www.facebook.com/EQUEmag">
          <AiFillFacebook />
        </a>
        <a target="_blank" href="https://music.apple.com/profile/equemag">
          <FaApple />
        </a>
        <a
          target="_blank"
          href="https://open.spotify.com/user/9wpqqhftudq8n7yb7fb5secg5?si=aIPasUNBR524CdW63hp6yA"
        >
          <FaSpotify />
        </a>
      </div>

      <div className="columns bottom-links is-vcentered">
        <div className="column is-narrow">
          <Link to="/">
            <img src={LOGO_WHITE} className="logo" />
          </Link>
        </div>

        <div className="column right-links">
          <div className="last">
          © Copyright EQUE 2020.
          </div>

          <div>
            <Link to="/about">
              About Us
            </Link>
          </div>

                </div>
      </div>
    </div>
  </footer>
)

class Layout extends React.Component {
  state = {
    sideNav: false,
  }

  handleSideNavToggle() {
    this.setState({
      ...this.state,
      sideNav: !this.state.sideNav,
    })
  }

  render() {
    let showGrid = false
    if (
      typeof window !== `undefined` &&
      window &&
      window.location.search === '?grid=true'
    ) {
      showGrid = true
    }

    const { children } = this.props
    const { sideNav } = this.state

    return (
      <div className="app-wrapper">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <StickyContainer>
          <div className={'sidenav' + (sideNav ? ' open' : '')}>
            <div className="close">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  this.handleSideNavToggle()
                }}
              >
                <IoMdClose />
              </a>
            </div>
            <div className="sidenavcentre">
            <div className="links">
            <Link to={`/`} onClick={this.handleSideNavToggle.bind(this)}>
                Home
              </Link>
              <Link to={`/about`} onClick={this.handleSideNavToggle.bind(this)}>
                About Us
              </Link>
            </div>

            <div className="social">
              <a target="_blank" href="http://instagram.com/eque_mag">
                <FiInstagram /> Instagram
              </a>
              <a target="_blank" href="https://twitter.com/eque_mag/">
                <FaTwitter /> Twitter
              </a>
              <a target="_blank" href="https://www.facebook.com/EQUEmag">
                <AiFillFacebook /> Facebook
              </a>
              <a
                target="_blank"
                href="https://open.spotify.com/user/9wpqqhftudq8n7yb7fb5secg5?si=aIPasUNBR524CdW63hp6yA"
              >
                <FaSpotify /> Spotify
              </a>
              <a target="_blank" href="https://music.apple.com/profile/equemag">
                <FaApple /> Apple Music
              </a>
            </div>
          </div></div>

          <div className={'app-container' + (sideNav ? ' sidenav-open' : '')}>
            <div
              className="overlay"
              onClick={this.handleSideNavToggle.bind(this)}
            ></div>

            {showGrid && (
              <div className="grid-overlay container">
                <div className="columns">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
                    <div className="column">
                      <span></span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <ReactTooltip place="top" type="dark" effect="solid" />

            <Header handleSideNavToggle={this.handleSideNavToggle.bind(this)} />

            {children}

            <Footer />
          </div>
        </StickyContainer>
      </div>
    )
  }
}

export default Layout
